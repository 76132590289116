import requestChina from '@/utils/requestChina'
import requestEn from '@/utils/requestEn'
//旗下公司
//菜单
export function getZqxgsListChinaData() {
    return requestChina({
        url: 'index/zqxgsList',
        method: 'post'
    });
}
export function getZqxgsListEnData() {
    return requestEn({
        url: 'index/zqxgsList',
        method: 'post'
    });
}
//产品中心
//菜单
export function getCpListChinaData() {
    return requestChina({
        url: 'cp/cpList',
        method: 'post'
    });
}
export function getCpListEnData() {
    return requestEn({
        url: 'cp/cpList',
        method: 'post'
    });
}
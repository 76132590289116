const routes = [
    //首页
    {
        path: '/',
        name: 'HomePage',
        meta: { china:'首 页',en:'Home' },
        component: () => import('@/views/HomePage.vue'),
    },
    //走进康庄
    {
        path: '/GoHome',
        name: 'GoHome',
        meta: { china:'走进康庄',en:'About us' },
        component: () => import('../views/GoHome/Index.vue'),
        children: [
            {
                path: '/GoHome/Synopsis',
                name: 'Synopsis',
                meta: { china:'企业简介',en:'Group Profile' },
                component: () => import('../views/GoHome/Synopsis.vue'),
            },
            {
                path: '/GoHome/Culture',
                name: 'Culture',
                meta: { china:'企业文化',en:'Group Culture' },
                component: () => import('../views/GoHome/Culture.vue'),
            },
            {
                path: '/GoHome/Honour',
                name: 'Honour',
                meta: { china:'荣誉资质',en:'Honor Qualification' },
                component: () => import('../views/GoHome/Honour.vue'),
            },
			{
			    path: '/GoHome/Corporate',
			    name: 'Corporate',
			    meta: { china:'企业宣传片',en:'Group Video' },
			    component: () => import('../views/GoHome/Corporate.vue'),
			}
        ]
    },
    //产品中心
    {
        path: '/ProductCenter',
        name: 'ProductCenter',
        meta: { china:'产品中心',en:'Product' },
        component: () => import('../views/ProductCenter/Index.vue'),
        children: [
            {
                path: '/ProductCenter/Board',
                name: 'Board',
                meta: { china:'',en:'' },
                component: () => import('../views/ProductCenter/Board.vue'),
            }
        ]
    },
    //工程案例
    {
        path: "/projectCase",
        name: "projectCase",
        meta: { china:'工程案例',en:'Case' },
        component: () => import('../views/ProjectCase/Index.vue'),
		children: [
			{
				path: '/ProjectCase/Housing',
				name: 'Housing',
				meta: { china:'住宅建筑',en:'Housing' },
				component: () => import('../views/ProjectCase/Housing.vue'),
			},
			{
				path: '/ProjectCase/Public',
				name: 'Public',
				meta: { china:'公共建筑',en:'Public' },
				component: () => import('../views/ProjectCase/Public.vue'),
			},
			{
				path: '/ProjectCase/Industrial',
				name: 'Industrial',
				meta: { china:'工业建筑',en:'Industrial' },
				component: () => import('../views/ProjectCase/Industrial.vue'),
			},
			{
				path: '/ProjectCase/Commercial',
				name: 'Commercial',
				meta: { china:'商业建筑',en:'Commercial' },
				component: () => import('../views/ProjectCase/Commercial.vue'),
			},
			{
				path: '/ProjectCase/Foreign',
				name: 'Foreign',
				meta: { china:'国外建筑',en:'Overseas' },
				component: () => import('../views/ProjectCase/Foreign.vue'),
			},
			{
			    path: '/ProjectCase/Detail',
			    name: "Detail",
			    meta: { china:'详情',en:'Detail' },
			    component: () => import('../views/ProjectCase/Detail.vue')
			}
		]
    },
    //旗下公司
    {
        path: '/BranchCompany',
        name: "BranchCompany",
        meta: { china:'旗下公司',en:'Subsidiary' },
        component: () => import('../views/BranchCompany/Index.vue'),
		children: [
			{
				path: '/BranchCompany/Main',
				name: 'BranchCompany',
				meta: { china:'',en:'' },
				component: () => import('../views/BranchCompany/Main.vue'),
			},
			{
				path: '/BranchCompany/Detail',
				name: 'BranchCompanyDetail',
				meta: { china:'',en:'' },
				component: () => import('../views/BranchCompany/Detail.vue'),
			},
		]
    },
    //技术实力
    {
        path: '/TechnicalStrength',
        name: "TechnicalStrength",
        meta: { china:'技术实力',en:'Strength' },
        component: () => import('../views/TechnicalStrength/Index.vue'),
		children: [
			{
				path: '/TechnicalStrength/Design',
				name: 'Design',
				meta: { china:'研发设计',en:'R&D Design' },
				component: () => import('../views/TechnicalStrength/Design.vue'),
			},
			{
				path: '/TechnicalStrength/Fruit',
				name: 'Fruit',
				meta: { china:'科技成果',en:'Technological Achievement' },
				component: () => import('../views/TechnicalStrength/Fruit.vue'),
			}
		]
    },
    //新闻中心
    {
        path: '/news',
        name: "news",
        meta: { china:'新闻中心',en:'News' },
        component: () => import('../views/News/Index.vue'),
		children: [
			{
				path: '/News/CompanyNews',
				name: 'CompanyNews',
				meta: { china:'公司新闻',en:'Group News' },
				component: () => import('../views/News/CompanyNews.vue'),
			},
			{
				path: '/News/Information',
				name: 'Information',
				meta: { china:'信息公开',en:'Open News' },
				component: () => import('../views/News/Information.vue'),
			},
			{
				path: '/News/Dynamics',
				name: 'Dynamics',
				meta: { china:'行业动态',en:'Industry News' },
				component: () => import('../views/News/Dynamics.vue'),
			},
			{
				path: '/News/leadership',
				name: 'leadership',
				meta: { china:'资料查看',en:'Material Viewing' },
				component: () => import('../views/News/leadership.vue'),
			},
			{
			    path: '/News/Detail',
			    name: "NewsDetail",
			    meta: { china:'详情',en:'Detail' },
			    component: () => import('../views/News/Detail.vue')
			}
		]
    },
    //联系我们
    {
        path: '/ConnectUs',
        name: "ConnectUs",
        meta: { china:'联系我们',en:'Contact us' },
        component: () => import('../views/ConnectUs/Index.vue'),
        children: [
			{
			   path: '/ConnectUs/ContactInformation',
			   name: 'ContactInformation',
			   meta: { china:'联系我们',en:'Contact Us' },
			   component: () => import('../views/ConnectUs/ContactInformation.vue'),
			},
			{
			   path: '/ConnectUs/ContactInformation',
			   name: 'ContactInformation',
			   meta: { china:'在线留言',en:'Online Message' },
			   component: () => import('../views/ConnectUs/ContactInformation.vue'),
			},
        ]
    },
    //搜索
    {
        path: '/Search',
        name: "Search",
        meta: { title: "搜索" },
        component: () => import('../views/Search.vue'),
		children: [
			{
			   path: '/ConnectUs/Search',
			   name: 'Search',
			   meta: { china:'搜索',en:'Search' },
			   component: () => import('../views/Search.vue'),
			},
		]
    },
    //详情
    {
        path: '/detail',
        name: 'detail',
        meta: { title: '详情', type: 'detail' },
        component: () => import('../views/Detail.vue')
    },
	
]

export default routes
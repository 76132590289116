import axios from 'axios';
import store from '@/store'

//axios二次封装
// 1.配置基础路径和超时限制
const instance = axios.create({
    baseURL: ' https://www.kzkg.com/api/',
    timeout: 5000
});

// 添加请求拦截器
instance.interceptors.request.use(function (config) {
    // 在发送请求之前做些什么
	config.headers.language = store.state.lang
    return config;
}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
});
// 3.返回的响应不再需要从data属性当中拿数据，而是响应就是我们要的数据
instance.interceptors.response.use(
    response => {
        return response.data
        // return response;
    },
    error => {
        // 错误的时候，一般会有提示信息，如果有错误信息就返回错误信息
        // 没有就返回一个'未知错误，请联系管理员'
        if (error && error.response) {
            // switch (error.response.status) {
            //     case 400:
            //         error.message = '请求错误';
            //         break;
            //     case 401:
            //         error.message = '请求错误';
            //         break;
            //     default:
            //         error.message = '未知错误，请联系管理员';
            // }
        }
        // return Promise.reject(error);
    }
);
// 4.导出封装好的axios实例
export default instance;
// Path: src\utils\request.js
// Compare this snippet from src\api\api.js:
// import request from '@/utils/request';
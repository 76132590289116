import request from '@/utils/request';
//网站配置数据
export function getIndexData() {
    return request({
        url: 'index/indexConfig',
        method: 'post',
        
    });
}
//轮播图
export function getIndexAd(params) {
    return request({
        url: 'index/indexAd',
        method: 'post',
        params: params
    });
}
//首页数据
//关于我们
export function getAboutUsData() {
    return request({
        url: 'index/aboutUs',
        method: 'post'
    });
}
//产品中心
export function getCpClassData() {
    return request({
        url: 'index/cpClass',
        method: 'post'
    });
}
//产品描述
export function getCpmsData() {
    return request({
        url: 'index/cpms',
        method: 'post'
    });
}
//新闻数据
export function getNewsData(type) {
    return request({
        url: 'index/news',
        method: 'post',
		params: {
		    classname:type
		}
    });
}
//工程案例
export function getGcalData() {
    return request({
        url: 'index/gcal',
        method: 'post'
    });
}
//科技成果
export function getKjcgData() {
    return request({
        url: 'index/kjcg',
        method: 'post'
    });
}
//企业文化
export function getIndexQywhData() {
    return request({
        url: 'index/qywh',
        method: 'post'
    });
}
//走进康庄
//企业简介
export function getJianjieData() {
    return request({
        url: 'go/jianjie',
        method: 'post'
    });
}
//企业文化
export function getQywhData() {
    return request({
        url: 'go/qywh',
        method: 'post'
    });
}
//荣誉资质
export function getRyzzData(page) {
    return request({
        url: 'go/ryzz',
        method: 'post',
		params: {
		    page:page
		}
    });
}
//企业宣传片
export function getQyxcpData(page) {
    return request({
        url: 'go/qyxcp',
        method: 'post',
		params: {
		    page:page
		}
    });
}
//产品中心
//菜单
export function getCpListData() {
    return request({
        url: 'cp/cpList',
        method: 'post'
    });
}
// 详情
export function getCpCpInfoData(id) {
    return request({
        url: 'cp/cpInfo',
        method: 'post',
		params: {
		    id:id
		}
    });
}
//工程案例
export function getGcalListData(params) {
    return request({
        url: 'gcal/gcalList',
        method: 'post',
		params: params
    });
}
//工程案例详情
export function getCpInfoData(id) {
    return request({
        url: 'gcal/cpInfo',
        method: 'post',
		params: {
		    id:id
		}
    });
}
//旗下公司
export function getZqxgsListData() {
    return request({
        url: 'index/zqxgsList',
        method: 'post'
    });
}
//旗下公司详情
export function getZqxgsInfoData(id) {
    return request({
        url: 'index/zqxgsInfo',
        method: 'post',
		params: {
		    id:id
		}
    });
}
//技术实力
//研发设计
export function getYfsjListData() {
    return request({
        url: 'index/yfsjList',
        method: 'post'
    });
}
//科技成果
export function getKjcgListData(page) {
    return request({
        url: 'index/kjcgList',
        method: 'post',
		params: {
		    page:page
		}
    });
}
// 新闻中心
export function getNewsListData(page,classname,p) {
    return request({
        url: 'index/newsList',
        method: 'post',
		params: {
		    page:page,
			classname:classname,
			p:p
		}
    });
}
//新闻详情
export function getNewsInfoData(id) {
    return request({
        url: 'index/newsInfo',
        method: 'post',
		params: {
		    id:id
		}
    });
}
// 留言
export function getAddLiuyanData(params) {
    return request({
        url: 'index/addLiuyan',
        method: 'post',
		params: params
    });
}